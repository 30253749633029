import { environmentConfig } from '../config/environment/environmentConfig'
import { flattenDeep } from 'lodash-es'
import color from 'color'

const getThemeByHostname = (hostname: string) => {
    return environmentConfig.themes.find(theme => {
        return theme.hostnames.find(themeHostname => {
            return themeHostname.trim().toLowerCase() === hostname?.replace(/:\d+/, '')
        })
    })
}

const getRgbTailwindColor = (hexColor: string) => {
    const colorObject = color(hexColor)
    return `${colorObject.red()} ${colorObject.green()} ${colorObject.blue()}`
}

const buildColorString = (colorPath, value) => {
    const key = colorPath.join('-').toLowerCase()
    return `--color-${key}: ${getRgbTailwindColor(value)};\n`
}

const processColors = (colorObj, path = []) => {
    return Object.entries(colorObj).map(([key, value]) => {
        if (typeof value === 'object') {
            return processColors(value, [...path, key])
        } else {
            return buildColorString([...path, key], value)
        }
    })
}

const getThemeStyleSheetByHostname = (hostname: string) => {
    const theme = getThemeByHostname(hostname)
    if (!theme) {
        return
    }
    const colorsString = flattenDeep(processColors(theme.colors)).join('')

    return `:root {\n${colorsString}\n}`
}

export default {
    getThemeByHostname,
    getThemeStyleSheetByHostname
}

import Button from '../components/base/button/Button'
import Layout from '../components/layouts/Layout'
import ThemeService from '../services/ThemeService'

const Custom404 = () => {
    // This page is not translated because nextjs error page doesn't support getServerSideProps
    // And i18n in nextjs requires getServerSideProps / getStaticProps...

    const getStyles = () => {
        return (
            typeof window !== 'undefined' && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: ThemeService.getThemeStyleSheetByHostname(window.location.hostname)
                    }}
                />
            )
        )
    }

    return (
        <Layout title='Stránka nenalezena / Page not found' appendHeaders={getStyles()}>
            <div className='text-center md:text-start flex justify-center items-center min-h-screen w-full bg-white'>
                <div className='sm:flex text-seco'>
                    <div className='flex flex-col'>
                        <p className='text-4xl font-extrabold text-primary sm:text-5xl'>404</p>
                        <small className='text-sm text-gray-500'>Page not found</small>
                    </div>
                    <div className='sm:ml-6'>
                        <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
                            <h1 className='text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl'>
                                Stránka nenalezena
                            </h1>
                            <p className='mt-1 text-base text-gray-500'>
                                Zkontrolujte prosím adresu URL v adresním řádku a zkuste to znovu.
                            </p>
                        </div>
                        <div className='justify-center md:justify-start mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6'>
                            <Button as='a' href='/app' className='w-32'>
                                Zpět
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Custom404
